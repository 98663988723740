import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './config/Theme';
import { GlobalStyles } from './config/GlobalStyles';
import NavigationHeader from './components/NavigationHeader/NavigationHeader';
import { MuiTheme } from './config/MuiTheme';
import Router from './routes/router';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <ThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <BrowserRouter>
          <NavigationHeader />
          <Router />
          <ToastContainer autoClose={5000} theme="colored" />
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </ThemeProvider>
  );
}

export default App;
