import React, { FunctionComponent } from "react";
import { StyledLinkButton } from "./styles";
import { Link } from "react-router-dom";

interface LinkButtonProps {
  linkTo: string;
  bgColor: string;
  textColor: string;
  openInNewTab: boolean;
  title: string;
  width?: number;
  hoverColor?: string;
  leftIcon?: string;
}

const LinkButton: FunctionComponent<LinkButtonProps> =
  ({ bgColor, textColor, linkTo, openInNewTab, title, hoverColor, leftIcon, width = 180 }) => {
    return (
      <Link style={{ width: `${width}px` }} to={linkTo} target={openInNewTab ? '_blank' : '_self'}>
        <StyledLinkButton
          bgcolor={bgColor}
          textcolor={textColor}
          width={width}
          hovercolor={hoverColor}
          lefticon={leftIcon}
        >
          {leftIcon && <img src={leftIcon} alt="ícone do botão" />}
          {title}
        </StyledLinkButton>
      </Link>
    );
  }

export default LinkButton;