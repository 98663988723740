import styled from "styled-components";
import { Theme } from "../../config/Theme";
import Banner from '../../images/home-banner.svg';
import HomeBannerFilter from '../../images/home-banner-filter.svg';
import GetInContactPattern from '../../images/pattern-entre-em-contato.svg';
import { Grid } from "@mui/material";

interface ThemeProps {
  theme: Theme;
}

export const HomeBannerContainer = styled.section`
  width: 100vw;
  position: relative;
  height: 274px;

  @media (min-width: 900px) {
    height: 500px;
  }
`;

export const HomeBanner = styled.div`
  width: 100vw;
  background-image: url(${Banner});
  background-size: cover;
  height: 100%;
`;

export const BannerFilter = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${HomeBannerFilter});
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: 1600px) {
    background-size: cover;
  }
`;

export const PatternContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

export const BannerPhrase = styled.div<ThemeProps>`
  position: absolute;
  top: 25%;
  right: calc(29% - 85px);

  h1 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 2.08rem;
    line-height: 1.35;
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
  }

  @media (min-width: 900px) {
    right: calc(21% - 85px);
    h1 {
      font-size: 2.85rem;
    }
  }
`;

export const SolutionsContainer = styled.section<ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.colors.white};

  h2 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    font-size: 2.08rem;
    color: ${({ theme }) => theme.colors.grey.dark};
    margin-bottom: 40px;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    div {
      padding: 0 20px;
      margin-bottom: 15px;
    }

    @media (min-width: 900px) {
      margin-bottom: 50px;
      flex-direction: row;
      width: 45%;
      min-width: 800px;
      justify-content: space-between;
    }
  }

  .iconed-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;

    img {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 16px;
      color: ${({ theme }) => theme.colors.grey.dark};
      font-family: ${({ theme }) => theme.fonts.RalewayBold};
    }

    a {
      color: ${({ theme }) => theme.colors.grey.lighter};
      font-family: ${({ theme }) => theme.fonts.RalewayMedium};
      text-decoration: none;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
    }
  }
`;

export const BriefingBpContent = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  margin: 0 auto;
  margin-bottom: 80px;

  iframe {
    border: none;
    border-radius: 15px;
  }

  .midia-container {
    width: 50%;
    :nth-child(1) {
      width: 100%;
    }
  }
  .event img {
    border-radius: 30px;
  }

  .text-container {
    width: 370px;
    margin-left: 10px;
    line-height: 1.5;
    font-size: 1.071rem;
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    font-size: 2.75rem;
    color: ${({ theme }) => theme.colors.grey.dark};
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.grey.dark};
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: bold;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 764px) {
    width: 100%;
    flex-direction: column;

    :nth-of-type(2) {
      flex-direction: column-reverse;
    }

    .text-container {
      width: 320px;
    }

    .midia-container {
      width: 90%;
      max-width: 320px;

      :nth-child(1) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
`;

export const BriefingBpContainer = styled.section<ThemeProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey.light};
  width: 100vw;
  padding: 90px 0;

  @media (max-width: 764px) {
    .column-reverse {
      flex-direction: column-reverse;
    }
  }
`;

export const InChargeContainer = styled.div<ThemeProps>`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;

  .info {
    color: ${({ theme }) => theme.colors.grey.dark};
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
  }

  .incharge {
    color: #182825;
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    font-size: 2rem;
  }

  .email {
    color: ${({ theme }) => theme.colors.grey.lighter};
    font-family: ${({ theme }) => theme.fonts.RalewayRegular};
  }

  img,p {
    margin-bottom: 10px;
  }
`;

export const AssociadosContainer = styled.section<ThemeProps>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gDarkPrimary};
`;

export const AssociadosContent = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  max-width: 90%;

  .image-container {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    .antifraud-image {
      width: 50%;
      border-radius: 30px;
      max-width: 500px;
    }
    .antifraud-pattern {
      position: absolute;
      top:-60px;
      right: calc(30% - 70px);
      opacity: 0.7;
    }
    @media (max-width: 764px) {
      width: 100%;
      .antifraud-image {
        width: 70%;
      }
      .antifraud-pattern {
        right: calc(30% - 110px);
      }
    }
  }
  .text-container {
    width: 50%;
    line-height: 1.5;
    .antifraud-title {
      margin-bottom: 20px;

      .title-background {
        display: flex;
        width: 100%;
      }

      h3 {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.primary.dark};
        padding: 5px 10px 0px 10px;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 30px;
        margin-bottom: 12px;
      }

      h4 {
        font-size: 60px;
        font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
        line-height: 40px;
      }
    }
    .antifraud-content {
      margin-bottom: 20px;

      h5 {
        font-size: 18px;

      }

      p {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.grey.light};

        a {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
    .content-container {
      width: 70%;
      max-width: 545px;
      min-width: 300px;
      @media (max-width: 764px) {
        min-width: 0;
      }
    }

    h3 {
      font-size: 2.8rem;
      font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    }
    h4 {
      font-size: 45px;
    }
    h5, h4, a {
      font-family: ${({ theme }) => theme.fonts.RalewayBold};
    }
    a {
      color: ${({ theme }) => theme.colors.white};
    }
    p {
      font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    }
    @media (max-width: 764px) {
      width: 100%;
      display: flex;
      justify-content: center;

      .contents {
        margin-bottom: 35px;
      }
    }
  }

  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

export const AntiFraudContainer = styled.section<ThemeProps>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
`;

export const AntiFraudContent = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 50px 0;

  .image-container {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    .antifraud-image {
      width: 50%;
      border-radius: 30px;
      max-width: 500px;
    }
    .antifraud-pattern {
      position: absolute;
      top:-60px;
      right: calc(30% - 70px);
      opacity: 0.7;
    }
    @media (max-width: 764px) {
      width: 100%;
      .antifraud-image {
        width: 70%;
      }
      .antifraud-pattern {
        right: calc(30% - 110px);
      }
    }
  }
  .text-container {
    width: 50%;
    line-height: 1.5;
    margin-top: 15px;
    
    .antifraud-title {
      margin-bottom: 20px;

      .title-background {
        display: flex;
        width: 100%;
      }

      h3 {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.primary.dark};
        padding: 5px 10px 0px 10px;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 30px;
        margin-bottom: 12px;
      }

      h4 {
        font-size: 40px;
        font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
        line-height: 40px;
      }
    }
    .antifraud-content {
      margin-bottom: 20px;

      h5 {
        font-size: 18px;

      }

      p {
        font-size: 18px;
        color: ${({ theme }) => theme.colors.grey.light};

        a {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
    .content-container {
      width: 70%;
      max-width: 545px;
      min-width: 300px;
      @media (max-width: 764px) {
        min-width: 0;
      }
    }

    h3 {
      font-size: 2.8rem;
      font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    }
    h4 {
      font-size: 1.143rem;
    }
    h5, h4, a {
      font-family: ${({ theme }) => theme.fonts.RalewayBold};
    }
    a {
      color: ${({ theme }) => theme.colors.white};
    }
    p {
      font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    }
    @media (max-width: 764px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

export const GetInContactContainer = styled(Grid) <ThemeProps>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.grey.dark};
  padding-top: 30px;
  position: relative;
  z-index: 999;

  background-image: url(${GetInContactPattern});
  background-position: right;

  background-repeat: no-repeat;

  div {
    display: flex;
    justify-content: center;
  }

  div:nth-of-type(2), div:nth-of-type(3) {
    align-items: center;
  }

  h3 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 3rem;
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    line-height: 1.2;
  }


  @media (max-width: 900px) {
    h3 {
      font-size: 2.08rem;
    }

    div:nth-of-type(1), div:nth-of-type(3) {
      margin-bottom: 20px;
    }

    background-size: cover;
  }
`;