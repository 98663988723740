import styled from 'styled-components';
import { Theme } from '../../config/Theme';

interface ThemeProps {
  theme: Theme;
}

export const CarreerBannerContent = styled.span<ThemeProps>`
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: ${({ theme }) => theme.fonts.RalewayBold};
  }
`;

export const CarreerBpInfoContainer = styled.section<ThemeProps>`
  width: 100vw;
  margin: 0 auto;
  padding: 40px 15vw;

  p {
    font-size: 1.143rem;
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    color: ${({ theme }) => theme.colors.grey.dark};
    line-height: 1.5;
  }
  @media (max-width: 768px) {
    p {
      font-size: 1.25rem;
    }
    padding: 40px 5vw;
  }

  @media (max-width: 430px) {
   margin-top: 100px;
  }

  @media (min-width: 1900px) {
    padding: 40px 18vw;
  }
`;

export const SendCvContainer = styled.section<ThemeProps>`
  width: 100vw;
  margin: 0 auto;
  padding: 60px 15vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.white};
  h2 {
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    color: ${({ theme }) => theme.colors.grey.dark};
  };

  @media (max-width: 620px) {
    flex-direction: column;
    h2 {
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    padding: 60px 5vw;
  }
  @media (min-width: 1900px) {
    padding: 60px 25vw;
  }
`;

export const ReasonsToWorkContainer = styled.section<ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.colors.grey.light};

  h2 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    font-size: 2.8rem;
    color: ${({ theme }) => theme.colors.grey.dark};
    margin: 40px 5px;
    text-align: center;
  }

  .icons-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;

    div {
      padding: 0 0px;
      margin-bottom: 15px;
    }

    @media (min-width: 900px) {
      flex-direction: row;
      width: 50%;
      min-width: 800px;
      justify-content: space-between;

      div:nth-of-type(2) {
        margin: 0 70px;
      }
    }
  }

  .iconed-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 270px;



    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 1.143rem;
      text-align: center;
      margin-bottom: 16px;
      color: ${({ theme }) => theme.colors.grey.dark};
      font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    }
  }
`;

export const BpBenefits = styled.section<ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.colors.white};

  h2 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    font-size: 1.429rem;
    color: ${({ theme }) => theme.colors.grey.dark};
    margin: 40px 5px;
    text-align: center;
  }

  .icons-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;

    div {
      padding: 0 0px;
      margin-bottom: 15px;
    }

    @media (min-width: 900px) {
      flex-direction: row;
      width: 50%;
      min-width: 800px;
      justify-content: space-between;
    }
  }

  .iconed-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 170px;

    img {
      margin-bottom: 24px;
    }

    p {
      font-size: 1.143rem;
      text-align: center;
      margin-bottom: 16px;
      color: ${({ theme }) => theme.colors.grey.dark};
      font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    }
  }
`;

export const CarreerOportunities = styled.section<ThemeProps>`
  width: 100vw;
  padding: 50px 25vw;
  background-color: ${({ theme }) => theme.colors.grey.light};

  display: flex;
  flex-direction: column;

  .oportunities-title {
    h2 {
      font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
      font-size: 1.429rem;
      color: ${({ theme }) => theme.colors.grey.dark};
      text-align: left;
    }
  }

  .oportunities-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }

  .oportunities-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;


    p {
      color: ${({ theme }) => theme.colors.grey.lighter};
      font-size: 1.143rem;
      font-family: ${({ theme }) => theme.fonts.RalewayMedium};
      margin-bottom: 20px;
    };
  }

  @media (max-width: 768px) {
    padding: 50px 10vw;

    .oportunities-container {
      flex-direction: column;
    }

  }
`;

export const TrainingContainer = styled.section<ThemeProps>`
  width: 100vw;
  padding: 50px 25vw;
  background-color: ${({ theme }) => theme.colors.grey.light};

  display: flex;
  flex-direction: row;

  .image-container {
    width: 50%;
    img {
      min-width: 400px;
      width: 100%;
    }
  }

  .text-container {
    h2 {
      font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
      font-size: 2.8rem;
      color: ${({ theme }) => theme.colors.grey.dark};
      margin-bottom: 30px;
      line-height: 1;
    }

    margin-left: 15px;

    p {
      font-size: 1.143rem;
      font-family: ${({ theme }) => theme.fonts.RalewayMedium};
      color: ${({ theme }) => theme.colors.grey.dark};
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1700px) {
    padding: 50px 15vw;
  }
  @media (max-width: 1200px) {
    padding: 50px 5vw;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 50px 10vw;
    .image-container {
      margin-bottom: 15px;
      width: 100%;
      img {
        min-width: 300px;
      }
    }
  }
`;