import styled from "styled-components";
import { Theme } from "../../config/Theme";
import AntiFraudPattern from '../../images/pattern-antifraud.png';
import { Grid } from "@mui/material";

interface ThemeProps {
  theme: Theme;
}

export const AntiFraudContainer = styled.section<ThemeProps>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url(${AntiFraudPattern});
    background-position: 50% 10%;
    background-size: contain;
  }
`;

export const AntiFraudContent = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  position: relative;

  .image-container {
    width: 50%;
    display: flex;
    justify-content: center;
    .antifraud-image {
      width: 50%;
      border-radius: 30px;
      max-width: 500px;
    }
    @media (max-width: 764px) {
      width: 100%;
      .antifraud-image {
        width: 70%;
      }
    }
  }
  .text-container {
    width: 50%;
    line-height: 1.5;
    .antifraud-title {
      margin-bottom: 60px;
      @media (max-width: 764px) {
        margin-bottom: 20px;
      }
    }
    .content-container {
      width: 70%;
      max-width: 545px;
      min-width: 300px;
      @media (max-width: 764px) {
        min-width: 0;
      }
    }

    h3 {
      font-size: 2.8rem;
      font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    }
    h4 {
      font-size: 1.143rem;
    }
    h5, h4, a {
      font-family: ${({ theme }) => theme.fonts.RalewayBold};
    }
    a {
      color: ${({ theme }) => theme.colors.white};
    }
    p {
      font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    }
    @media (max-width: 764px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

export const AntiFraudTipsContainer = styled(Grid) <ThemeProps>`
  width: 100vw;
  padding: 50px 0px;
  background-color: ${({ theme }) => theme.colors.grey.light};
  justify-content: space-around;
  @media (max-width: 764px) {
    padding: 50px 20px;
  }

  .container-content {
    display: flex;
    flex-wrap: wrap;
    width: 1100px;
    @media (max-width: 1200px) {
      max-width: 750px;
    }
  }

`;

export const AntiFraudTipCard = styled(Grid) <ThemeProps>`
  display: flex;
  justify-content: center;
`;

export const AntiFraudTipContent = styled.div<ThemeProps>`
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;
  width: 360px;
  height: 300px;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 40px 28px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey.lightActive};

  @media (max-width: 764px) {
    width: 300px;
    height: 270px;
  }

  p {
    margin-top: 20px;
    line-height: 2;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey.lighter};
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular};
    font-size: 0.857rem;
  }
`;