import { setupYup } from '../../config/Yup';
import { isValidCPF, isValidCNPJ } from '../../utils/validateDoc';

const Yup = setupYup();

export const schema = Yup.object().shape({
  Cpf_Cnpj: Yup.string()
    .required()
    .test('document', 'Documento Inválido', function (value: any) {
      if (!value) return;
      if (value.length > 11) {
        return isValidCNPJ(value);
      }
      return isValidCPF(value);
    }),
  Email: Yup.string().email().required(),
  InstituicaoFinanceira: Yup.string().required(),
  Mensagem: Yup.string().required(),
  MotivoContato: Yup.string().required(),
  Telefone: Yup.string().required().test('phone', 'Telefone Inválido', function (value: any) {
    if (!value) return;
    return value.replace(/\D/g, '').length === 11;
  })
});
