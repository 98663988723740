import { FunctionComponent } from "react";
import { TopBannerContainer, TopBannerSection, InfoBanner, TopFormBannerContainer, FormBanner } from "./styles";

interface TopFormBannerProps {
  shadowColor: string;
  bannerImg: string;
  bgColor: string;
  bannerHeight?: number;
  bannerHeightMobile?: string;
  bannerHeightMid?: string;
  children?: any;
}

const TopFormBanner: FunctionComponent<TopFormBannerProps> = ({
  shadowColor,
  bannerImg,
  bgColor,
  bannerHeight,
  bannerHeightMobile,
  bannerHeightMid,
  children
}) => {
  return (
    <TopFormBannerContainer bannerheight={bannerHeight} bannerheightmobile={bannerHeightMobile} bannerheightmid={bannerHeightMid}>
      <TopBannerSection bannerimg={bannerImg} />
      <FormBanner shadowcolor={shadowColor} bgcolor={bgColor}>
        {children}
      </FormBanner>
    </TopFormBannerContainer>
  );
}

export default TopFormBanner;