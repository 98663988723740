import DomainEmail from '../../images/iconsAntiFraud/domain-email-tip.png';
import BarCode from '../../images/iconsAntiFraud/bar-code.png';
import BillTip from '../../images/iconsAntiFraud/bill-tip.png';

import BillValue from '../../images/iconsAntiFraud/bill-value.png';
import CheckedLetter from '../../images/iconsAntiFraud/checked-letter.png';
import LetterWarning from '../../images/iconsAntiFraud/letter-warning.png';

import Link from '../../images/iconsAntiFraud/link.png';
import MagnifyingGlass from '../../images/iconsAntiFraud/magnifying-glass.png';
import Person from '../../images/iconsAntiFraud/person.png';

import Pix from '../../images/iconsAntiFraud/pix.png';
import Warning from '../../images/iconsAntiFraud/warning.png';
import Wifi from '../../images/iconsAntiFraud/wifi.png';



interface CardsDataInterface {
  img: string;
  description: string;
  alt: string;
}


export const CardsData: CardsDataInterface[] = [
  {
    img: Pix,
    description: 'Não aceitamos pagamentos PIX ou transferências bancárias de qualquer forma;',
    alt: 'Ícone do símbolo do Pix.'
  },
  {
    img: BillTip,
    description: 'O beneficiário do boleto, será o credor do seu débito (banco/varejista) ou da Bellinati Perez;',
    alt: 'Ícone contendo a imagem de um boleto com uma moeda com um $ no centro.'
  },
  {
    img: MagnifyingGlass,
    description: 'O número do CNPJ do beneficiário (credor) estará sempre indicado no boleto. Caso o número do CNPJ informado não corresponda ao credor, desconfie;',
    alt: 'Ícone de uma Lupa'
  },

  {
    img: Person,
    description: 'O beneficiário do boleto nunca será uma pessoa física, ou uma microempresa (MEI);',
    alt: 'Ícone da silhueta de uma pessoa.'
  },
  {
    img: DomainEmail,
    description: 'Se você recebeu o boleto por e-mail, confira os dados do remetente. Se o emitente usar um domínio gratuito do tipo: “gmail”, “hotmail”, “yahoo”, dentre outros, não pague, é fraude;',
    alt: 'Ícone de carta de e-mail com um @ no centro'
  },
  {
    img: CheckedLetter,
    description: 'Se você recebeu boleto por aplicativos de mensagens (WhatsApp ou outros), e deseja confirmar a autenticidade, entre em contato pelo canal de atendimento contatobp@bellinatiperez.com.br;',
    alt: 'Ícone de uma carta'
  },

  {
    img: BarCode,
    description: 'A numeração do código de barras, deve ser a mesma da parte de cima e de baixo do boleto. Os números finais do código de barras devem ser exatamente o valor do boleto, com exceção dos cartões de crédito não cancelado;',
    alt: 'Ícone de um código de barras'
  },
  {
    img: Warning,
    description: 'Desconfie de acordos ou promessas com valores muito abaixo do seu débito;',
    alt: 'Ícone de um triângulo de alerta que possui um símbolo de exclamação no centro.'
  },
  {
    img: BillValue,
    description: 'Fique atento ao valor. Boletos falsos costumam ter valores diferentes do valor real da cobrança. Portanto, preste atenção ao valor antes de pagar o boleto;',
    alt: 'Ícone de um boleto.'
  },

  {
    img: Wifi,
    description: 'Evite usar redes Wi-Fi públicas. Redes Wi-Fi públicas são mais suscetíveis a ataques, o que pode facilitar a interceptação de dados. Portanto, prefira usar sua rede Wi-Fi doméstica ou um serviço de VPN para acessar sites e aplicativos bancários;',
    alt: 'Ícone do símbulo do Wi-Fi.'
  },
  {
    img: Link,
    description: 'Tenha cuidado com links enviados por e-mail ou SMS. Boletos falsos podem ser enviados por e-mail ou SMS com links maliciosos. Portanto, não clique em links desconhecidos;',
    alt: 'Ícone do símbulo de um Link'
  },
  {
    img: LetterWarning,
    description: 'Fique atento a possíveis golpes. Os criminosos costumam usar técnicas de engenharia social para enganar as vítimas. Portanto, fique atento a possíveis golpes, como ofertas irreais ou ameaças.',
    alt: 'Ícone de um notebook com uma carta na tela.'
  }
]