import { createGlobalStyle } from "styled-components";

import 'react-toastify/dist/ReactToastify.min.css';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-Medium';
    font-weight: 500;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(./fonts/Poppins-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Baloo-Tamma-SemiBold';
    font-weight: 600;
    src: local('Baloo Tamma SemiBold'), local('Baloo-Tamma-SemiBold'), url(./fonts/BalooTamma2-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Baloo-Tamma-Bold';
    font-weight: 700;
    src: local('Baloo Tamma Bold'), local('Baloo-Tamma-Bold'), url(./fonts/BalooTamma2-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Raleway-Regular';
    font-weight: 400;
    src: local('Raleway Regular'), local('Raleway-Regular'), url(./fonts/Raleway-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Raleway-Medium';
    font-weight: 500;
    src: local('Raleway Medium'), local('Raleway-Medium'), url(./fonts/Raleway-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Raleway-SemiBold';
    font-weight: 600;
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(./fonts/Raleway-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Raleway-Bold';
    font-weight: 700;
    src: local('Raleway Bold'), local('Raleway-Bold'), url(./fonts/Raleway-Bold.ttf) format('truetype');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  html {
    width: 100vw;
    font-size: 14px;
    background-color: ${({ theme }) => theme.colors.grey.light};
    overflow-x: hidden;
  }

  @media (max-width: 900px) {
    html {
      font-size: 12px;
    }
  }
`;
