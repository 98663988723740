import { FunctionComponent } from "react";
import { useTheme } from "styled-components";
import TopFormBanner from "../../components/TopBanner/TopFormBanner";
import ContactForm from "../../components/Forms/ContactForm";
import { LinkCardsContainer } from "./styles";
import LinkCard from "../../components/LinkCard/LinkCard";
import WorkAtBp from "../../images/banner-carreira.png";
import SapBanner from "../../images/banner-ouvidoria.png";
import ContactUsBanner from "../../images/fale-conosco-banner.png";
import { TipoContato } from "../../services/EnvioEmail/types";

interface FeedbackProps {

}

const Feedback: FunctionComponent<FeedbackProps> = () => {
  const theme = useTheme();
  const formText = "Para qualquer reclamação ou elogio, preencha as informações ao lado que retornaremos com uma posição sobre sua demanda assim que possível.";

  return (
    <>
      <TopFormBanner
        shadowColor={theme.colors.secondary}
        bannerImg={SapBanner}
        bgColor={theme.colors.grey.light}
      >
        <ContactForm
          formTitle="Ouvidoria"
          formText={formText}
          contactType={TipoContato.Ouvidoria}
        />
        <LinkCardsContainer>
          <LinkCard
            bgImg={ContactUsBanner}
            fadeColor={theme.colors.bpRed}
            linkTo="/fale-com-a-gente"
            linkText="Entre em contato"
          />
          <LinkCard
            bgImg={WorkAtBp}
            fadeColor={theme.colors.grey.dark}
            linkTo="/carreira"
            linkText="Trabalhe na BP"
          />
        </LinkCardsContainer>
      </TopFormBanner>
    </>
  );
}

export default Feedback;