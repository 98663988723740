import styled from "styled-components";

export const LinkCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;

  div:nth-of-type(1) {
    margin-right: 20px;
  }

  @media(max-width: 1329px) {
    margin-top: 0px;
    flex-direction: column;
    align-items: center;

    div:nth-of-type(1) {
      margin-right: 0px;
    }
  }
`;