import React, { FunctionComponent, useState } from 'react';
import { Burger, NavLinkContainer } from './styles';
import RightNav from './RightNav';

interface BurgerButtonProps {

}

const NavLinks: FunctionComponent<BurgerButtonProps> = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <Burger openMenu={openMenu} onClick={() => setOpenMenu(!openMenu)}>
        <div></div>
        <div></div>
        <div></div>
      </Burger>
      <NavLinkContainer>
        <RightNav openMenu={openMenu} setOpenMenu={setOpenMenu} />
      </NavLinkContainer>
    </>
  );
}

export default NavLinks;