import { FunctionComponent } from "react";
import { TopBannerSection, InfoBanner, TopBannerContainer } from "./styles";

interface TopBannerProps {
  title: string;
  content?: any;
  topContent?: any;
  bottomContent?: any;
  formContent?: any;
  shadowColor: string;
  bannerImg: string;
  bgColor: string;
  bannerHeight?: number;
  bannerHeightMobile?: string;
  bannerHeightMid?: string;
  children?: any;
}

const TopBanner: FunctionComponent<TopBannerProps> = ({
  title,
  content,
  topContent,
  formContent,
  bottomContent,
  shadowColor,
  bannerImg,
  bgColor,
  bannerHeight,
  bannerHeightMobile,
  bannerHeightMid,
}) => {
  return (
    <TopBannerContainer bannerheight={bannerHeight} bannerheightmobile={bannerHeightMobile} bannerheightmid={bannerHeightMid}>
      <TopBannerSection bannerimg={bannerImg} />
      <InfoBanner shadowcolor={shadowColor} bgcolor={bgColor}>
        {!!topContent ? (
          <div className="side-content">
            <div>
              <img src={topContent} alt="Conteúdo do topo do banner informativo" />
            </div>
            <div className="title-content">
              <h1>{title}</h1>
              <p>{content}</p>
            </div>
          </div>
        ) : (
          <div className="title-content">
            <h1>{title}</h1>
            <p>{content}</p>
          </div>
        )}
        {!!bottomContent && (
          bottomContent
        )}
      </InfoBanner>
    </TopBannerContainer>
  );
}

export default TopBanner;