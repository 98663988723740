import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import { Theme } from "../../config/Theme";

interface ThemeProps {
  theme: Theme;
}

export const FooterContainer = styled.footer`
  background-color: #fff;
  width: 100vw;
`;

export const FooterContent = styled.div<ThemeProps>`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-family: ${({ theme }) => theme.fonts.PoppinsMedium};
  font-size: 14px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey.light};

  @media (max-width: 1280px) {
    margin: 0 50px;
  }

  @media (max-width: 764px) {
    margin: 0;
  }

  a {
    color: #4D4E53;
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const FooterLinksContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 34px;

  margin-bottom: 20px;

  @media (max-width: 1301px) {
    padding: 34px 20px 0 20px;
  }

  @media (max-width: 776px) {
    align-items: flex-start;
    flex-direction: column;

  }
`;

export const FooterLinkContent = styled(Box) <ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    font-size: 1.143rem;
    font-family: ${({ theme }) => theme.fonts.BalooTammaBold};
    margin-bottom: 10px;
  }

  p, a {
    font-size: 0.857rem;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium};
  }

  a {
    color: ${({ theme }) => theme.colors.secondary}
  }

  @media (max-width: 776px) {
    margin-bottom: 20px;

    p, a {
      font-size: 1rem;
    }

    h3 {
      font-size: 1.333rem;
    }
  }
`;

export const BpCertContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media (max-width: 776px) {
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
`;

export const BpIsoContainer = styled(Box)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1000px) {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    text-decoration: none;
  }
`;

export const BpCertText = styled(Box)`
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #E3000F4D;
  border-radius: 0px 5px 5px 0px;
  padding: 6.7px 6px;
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.PoppinsMedium};
  text-align: center;
  color: #767676;
`;

export const FooterLogoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 4px;
  img {
    margin-right: 16px;
  }

  @media (max-width: 764px) {
    flex-direction: column;
    margin-bottom: 20px;

    img {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const SocialMediasBp = styled(Box)`
  width: 80px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  @media (max-width: 776px) {
    width: 165px;
    img {
      width: 45px;
    }
  }
`;

export const LogoEnergiaRenovavel = styled(Box)`
`;