import styled from 'styled-components';
import { Grid } from '@mui/material';
import { Theme } from '../../config/Theme';

interface ThemeProps {
  theme: Theme;
}

export const SolutionsContainer = styled.section`
  width: 100vw;
  padding: 0 25vw;

  @media (max-width: 764px) {
    padding: 0 5vw;
  }
`;

export const SolutionsContent = styled(Grid) <ThemeProps>`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey.lightActive};
  padding-bottom: 80px;
`;

export const SolutionsItem = styled(Grid) <ThemeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  p {
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 1.143rem;
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    margin-left: 5px;
  }
`;

export const TecnologyInovationContainer = styled(Grid) <ThemeProps>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: 50px 0 0px 0;

  @media (max-width: 764px) {
    padding: 50px 0 0 0;
  }
`;

export const TecnologyInovationTopics = styled(Grid) <ThemeProps>`
  .title-container {
    margin-bottom: 40px;

    @media (max-width: 900px) {
      margin: 20px 0;
      text-align: center;
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 2.8rem;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    color: ${({ theme }) => theme.colors.grey.lighter};
    font-size: 1.286rem;
    line-height: 1.5;

    @media (max-width: 900px) {
      text-align: center;
    }
  }
`;

export const TecnologyInovationImageContainer = styled(Grid) <ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  div {
    width: 70%;
    img {
      width: 100%;
      max-width: 450px;
    }
  }

  @media (max-width: 900px) {
    justify-content: center;
  }
`;

export const TecnologyItemsTitle = styled.div <ThemeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 900px) {
    justify-content: center;
  }

  h5 {
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 1.429rem;
    margin-left: 10px;
    text-align: left;
    @media (max-width: 900px) {
      text-align: center;
    }
  }
`;

export const TecnologyItems = styled(Grid)`
  margin-bottom: 60px;
  padding-right: 70px;

  @media (max-width: 900px) {
    padding: 0 20px;
    margin-bottom: 40px;
  }
`;

export const TecnologyItemsContent = styled(Grid)`
  display: flex;

  @media (max-width: 900px) {
    justify-content: center;
  }
`;