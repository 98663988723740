import { FunctionComponent } from "react";
import { AntiFraudContainer, AntiFraudContent, AntiFraudTipsContainer, AntiFraudTipCard, AntiFraudTipContent } from "./styles";
import AntiFraudImg from "../../images/img-antifraude.png";
import ArrobaIcon from '../../images/iconsAntiFraud/domain-email-tip.png';
import { CardsData } from './CardsData';

interface AntiFraudProps {

}

const AntiFraud: FunctionComponent<AntiFraudProps> = () => {
  return (
    <>
      <AntiFraudContainer>
        <AntiFraudContent>
          <div className="image-container">
            <img src={AntiFraudImg} alt="Pessoa mexendo em um notebook com cadeados ao redor" className="antifraud-image" />
          </div>
          <div className="text-container">
            <div className="content-container">
              <div className="antifraud-title">
                <h3>RECOMENDAÇÕES DE CUIDADO ANTIFRAUDE</h3>
                <h4>PREPARAMOS UM MATERIAL PRA VOCÊ PRESTAR ATENÇÃO COM GOLPES E BOLETOS FALSOS.</h4>
              </div>
              <div className="antifraud-content">
                <p>
                  Caso você esteja na dúvida referente ao boleto recebido, procure atendimento no email: <a href="mailto:contatobp@bellinatiperez.com.br">contatobp@bellinatiperez.com.br</a>
                </p>
              </div>
            </div>
          </div>
        </AntiFraudContent>
      </AntiFraudContainer>

      <AntiFraudTipsContainer container spacing={2}>
        <div className="container-content">
          {CardsData.map((card, index) => {
            return (
              <AntiFraudTipCard item xs={12} sm={6} md={6} lg={4} key={index}>
                <AntiFraudTipContent>
                  <img src={card.img} alt={card.alt} />
                  <p>
                    {card.description}
                  </p>
                </AntiFraudTipContent>
              </AntiFraudTipCard>
            )
          })}
        </div>
      </AntiFraudTipsContainer>

    </>
  );
}

export default AntiFraud;