export interface Theme {
  colors: {
    primary: {
      light: string;
      lightHover: string;
      lightActive: string;
      normal: string;
      normalHover: string;
      normalActive: string;
      dark: string;
      darkHover: string;
      darkActive: string;
      darker: string;
    };
    secondary: string;
    secondaryHover: string;
    bpRed: string;
    bpBlue: string;
    terciary: string;
    grey: {
      light: string;
      lightHover: string;
      lightActive: string;
      lighter: string;
      normal: string;
      normalHover: string;
      normalActive: string;
      dark: string;
      darkHover: string;
      darkActive: string;
      darker: string;
    };
    white: string;
    gDarkPrimary: string;
    gPrimary: string;
    gTerciary: string;
  };
  fonts: {
    PoppinsRegular: string;
    PoppinsMedium: string;
    PoppinsSemiBold: string;
    BalooTammaSemiBold: string;
    BalooTammaBold: string;
    RalewayRegular: string;
    RalewayMedium: string;
    RalewaySemiBold: string;
    RalewayBold: string;
  };
}

export const theme: Theme = {
  colors: {
    primary: {
      light: '#FFEAEF',
      lightHover: '#FFE0E8',
      lightActive: '#FFBECF',

      normal: '#FF2E63',
      normalHover: '#E62959',
      normalActive: '#CC254F',

      dark: '#BF234A',
      darkHover: '#991C3B',
      darkActive: '#73152D',

      darker: '#591023'
    },

    secondary: '#A82047',
    secondaryHover: '#910e32',

    terciary: '#08D9D6',

    bpRed: '#7D1416',

    bpBlue: '#08D9D6',

    grey: {
      light: '#E9EAEB',
      lightHover: '#DEDFE1',
      lightActive: '#BBBDC0',

      lighter: '#838383',

      normal: '#252B34',
      normalHover: '#21272F',
      normalActive: '#1E222A',

      dark: '#1C2027',
      darkHover: '#161A1F',
      darkActive: '#111317',

      darker: '#0D0F12'
    },

    white: '#FFFFFF',

    gDarkPrimary: 'linear-gradient(#252A34, #FF2E63)',
    gPrimary: 'linear-gradient(#FF2E63, #A82047)',
    gTerciary: 'linear-gradient(#08D9D6, #EAEAEA)',
  },

  fonts: {
    PoppinsRegular: 'Poppins-Regular, sans-serif',
    PoppinsMedium: 'Poppins-Medium, sans-serif',
    PoppinsSemiBold: 'Poppins-SemiBold, sans-serif',

    BalooTammaSemiBold: 'Baloo-Tamma-SemiBold, sans-serif',
    BalooTammaBold: 'Baloo-Tamma-Bold, sans-serif',

    RalewayRegular: 'Raleway-Regular, sans-serif',
    RalewayMedium: 'Raleway-Medium, sans-serif',
    RalewaySemiBold: 'Raleway-SemiBold, sans-serif',
    RalewayBold: 'Raleway-Bold, sans-serif',
  },
};