import styled from 'styled-components';
import { Theme } from '../../config/Theme';

interface ThemeProps {
  theme: Theme;
}

export const BriefingBpContent = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70vw;
  margin: 0 auto;
  margin-bottom: 80px;

  .midia-container {
    width: 50%;
    :nth-child(1) {
      width: 80%;
      max-width: 501px;
    }
  }

  .text-container {
    width: 50%;
    margin-left: 10px;
    line-height: 1.5;
    font-size: 1.286rem;
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.grey.dark};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    margin: 10px 0;
    color: ${({ theme }) => theme.colors.grey.lighter};
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: bold;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 764px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 50px;

    :nth-of-type(2) {
      flex-direction: column-reverse;
    }

    .text-container {
      width: 320px;
    }

    .midia-container {
      width: 90%;
      max-width: 320px;

      :nth-child(1) {
        width: 80%;
        margin-bottom: 15px;
      }
    }
  }
`;

export const BriefingBpContainer = styled.section<ThemeProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.grey.light};
  width: 100vw;
  padding: 0;

  .column-reverse {
    .midia-container {
      text-align: right;
    }
  }

  @media (max-width: 764px) {
    padding-top: 120px;
    .column-reverse {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 430px) {
    padding-top: 0;
  }
`;