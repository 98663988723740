import { FunctionComponent } from "react";
import ContactUsBanner from "../../images/fale-conosco-banner.png";
import { useTheme } from "styled-components";
import ContactForm from "../../components/Forms/ContactForm";
import TopFormBanner from "../../components/TopBanner/TopFormBanner";
import { LinkCardsContainer } from "./styles";
import LinkCard from "../../components/LinkCard/LinkCard";
import WorkAtBp from "../../images/banner-carreira.png";
import SapBanner from "../../images/banner-ouvidoria.png";
import { TipoContato } from "../../services/EnvioEmail/types";


interface ContactUsProps {

}

const ContactUs: FunctionComponent<ContactUsProps> = () => {
  const theme = useTheme();
  const formText = "Se você tem dúvidas, entre em contato. " +
    "pode preencher o formulário ao lado ou entrar " +
    "em nossos outros canais por meio dos links " +
    "abaixo:";

  return (
    <>
      <TopFormBanner
        shadowColor={theme.colors.grey.dark}
        bannerImg={ContactUsBanner}
        bgColor={theme.colors.grey.light}
      >
        <ContactForm
          formTitle="Fale com a gente"
          formText={formText}
          contactType={TipoContato.FaleConosco}
        />
        <LinkCardsContainer>
          <LinkCard
            bgImg={WorkAtBp}
            fadeColor={theme.colors.grey.dark}
            linkTo="/carreira"
            linkText="Trabalhe na BP"
          />
          <LinkCard
            bgImg={SapBanner}
            fadeColor={theme.colors.bpRed}
            linkTo="/ouvidoria"
            linkText="Ouvidoria"
          />
        </LinkCardsContainer>
      </TopFormBanner>
    </>
  );
}

export default ContactUs;