import styled from "styled-components";
import { Theme } from "../../config/Theme";

interface ThemeProps {
  theme: Theme;
  fadecolor: string;
  bgimg: string;
}

export const CardContainer = styled.div`
  box-shadow: 0px 0px 37px 5px rgba(0,0,0,0.1);
  height: 230px;
  width: 480px;
  min-width: 280px;
  border-radius: 12px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 37px 5px rgba(0,0,0,0.2);
  }

  @media(max-width: 1329px) {
    width: 80%;
    margin-bottom: 20px;
    max-width: 350px;
    height: 150px;
  }
`;

export const Card = styled.div<ThemeProps>`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  cursor: pointer;
  background-image: url(${({ bgimg }) => bgimg});
  box-shadow: inset 0px -123px 83px -29px ${({ fadecolor }) => fadecolor};
  background-size: cover;
  background-position: center;
  position: relative;
  div {
    display: flex;
    align-items: center;
    padding: 5px 0;
    span, h3 {
      color: ${({ theme }) => theme.colors.white};
      font-family: ${({ theme }) => theme.fonts.RalewayBold};
      font-size: 1.5rem;
      line-height: 1;
    }

    span {
      margin-left: 5px
    }

    position: absolute;
    bottom: 29px;
    left: 34px;
  }


  @media(max-width: 1329px) {
    box-shadow: inset 0px -100px 83px -29px ${({ fadecolor }) => fadecolor}
  }
`;