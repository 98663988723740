import styled from "styled-components";

import { Theme } from "../../config/Theme";

interface ThemeProps {
  theme: Theme;
  shadowcolor?: string;
  bannerimg?: string;
  bgcolor?: string;
  bannerheight?: number;
  bannerheightmobile?: string;
  bannerheightmid?: string;
}

export const TopBannerContainer = styled.article<ThemeProps>`
  position: relative;
  height: ${({ bannerheight }) => bannerheight ? `${bannerheight}px` : '800px'};
  background-color: ${({ theme }) => theme.colors.grey.light};
  @media (max-width: 1100px) {
    height: ${({ bannerheightmid }) => bannerheightmid ? `${bannerheightmid}` : 'calc(1100px - 25vw)'};
  }
  @media (max-width: 760px) {
    height: ${({ bannerheightmobile }) => bannerheightmobile ? `${bannerheightmobile}` : 'calc(1100px - 50vw)'};
  }
`;

export const TopFormBannerContainer = styled.article<ThemeProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey.light};
`;

export const TopBannerSection = styled.section<ThemeProps>`
  width: 100vw;
  height: 500px;
  background-image: url(${({ bannerimg }) => bannerimg});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 764px) {
    background-position: top;
  }
`;

export const InfoBanner = styled.section<ThemeProps>`
  width: 70vw;
  padding: 40px;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 16px;
  position: absolute;
  top: 340px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px -13px 0px 0px ${({ shadowcolor }) => shadowcolor};
  color: ${({ theme }) => theme.colors.grey.dark};
  max-width: 1300px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    font-size: 2.8rem;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    font-size: 1.143rem;
    line-height: 1.5;
  }

  .title-content {
    margin-bottom: 20px;
  }

  .side-content {
    .title-content {
      line-height: 1;
    }
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 764px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      .title-content {
        line-height: 1.5;
      }
    }

    img {
      margin-right: 40px
    };
  }

  @media (max-width: 764px) {
    width: 97vw;
    padding: 40px 10px;
    top: 280px
  }
`;

export const FormBanner = styled.section<ThemeProps>`
  width: 70vw;
  padding: 40px;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 16px;
  position: relative;
  top: -155px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px -13px 0px 0px ${({ shadowcolor }) => shadowcolor};
  color: ${({ theme }) => theme.colors.grey.dark};
  max-width: 1300px;

  h1 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    font-size: 2.8rem;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    font-size: 1.143rem;
    line-height: 1.5;
  }

  .title-content {
    margin-bottom: 20px;
  }

  .side-content {
    .title-content {
      line-height: 1;
    }
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 764px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      .title-content {
        line-height: 1.5;
      }
    }

    img {
      margin-right: 40px
    };
  }

  @media (max-width: 764px) {
    width: 100vw;
    padding: 40px 10px;
  }
`;