import styled from 'styled-components';
import { Theme } from '../../config/Theme';

interface ThemeProps {
  theme: Theme;
}

const LinkFooterContainer = styled.section<ThemeProps>`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey.dark};

  padding: 50px;
  text-align: center;

  h2 {
    font-size: 2.8rem;
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 20px;
  }
  p {
    font-size: 1.143rem;
    font-family: ${({ theme }) => theme.fonts.RalewayMedium};
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    margin-bottom: 20px;
  }
`;

export default LinkFooterContainer;