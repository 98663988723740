import { FunctionComponent } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card, CardContainer } from "./styles";
import { useNavigate } from "react-router-dom";

interface LinkCardProps {
  bgImg: string;
  linkTo: string;
  fadeColor: string;
  linkText: string;
}

const LinkCard: FunctionComponent<LinkCardProps> = ({ bgImg, fadeColor, linkText, linkTo }) => {
  const nav = useNavigate();

  const handleLink = () => {
    nav(linkTo);
  }
  return (
    <>
      <CardContainer onClick={handleLink}>
        <Card bgimg={bgImg} fadecolor={fadeColor}>
          <div>
            <h3>{linkText}</h3> <span><ArrowForwardIcon /></span>
          </div>
        </Card>
      </CardContainer>
    </>
  );
}

export default LinkCard;