import styled from "styled-components";
import { Theme } from "../../config/Theme";
import { Grid } from "@mui/material"

interface NavProps {
  theme: Theme;
}

interface UlProps {
  theme: Theme;
}

interface BurgerProps {
  theme: Theme;
  openMenu: boolean;
  onClick: () => void;
}

interface BurgerUlProps {
  openMenu: boolean;
  theme: Theme;
}

interface DropdownLinkProps {
  dropdownopen: boolean;
  theme: Theme;
}

export const ContainerGrid = styled(Grid)`
  position: relative;
  z-index: 9;
  .gridItens {
    display: flex;
    align-items: center;
  }
  :nth-child(2) {
    @media (min-width: 1050px) {
      justify-content: center;
    }
  }
  :nth-child(3) {
    justify-content: flex-end;
    @media (max-width: 1050px) {
      justify-content: flex-start;
    }
    @media (max-width: 900px) {
      justify-content: center;
    }
  }
`;

export const Nav = styled.nav<NavProps>`
  width: 100vw;
  padding: 23px 55px;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 1050px) {
    padding: 23px 23px ;

    .logo {
      padding-bottom: 10px;
    }
  }
`;

export const Ul = styled.ul<UlProps>`
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  li {
    position: relative;
    padding: 18px 10px;
    font-family: ${({ theme }) => theme.fonts.RalewayBold};
    a {
      color: ${({ theme }) => theme.colors.grey.dark};
      text-decoration: none;
      &:active {
        color: ${({ theme }) => theme.colors.grey.dark};
      }
      &:visited {
        color: ${({ theme }) => theme.colors.grey.dark};
      }
    }
  }
  .active-page {
    color: ${({ theme }) => theme.colors.secondary};
    &:active {
      color: ${({ theme }) => theme.colors.secondary};
    }
    &:visited {
      color: ${({ theme }) => theme.colors.secondary};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 5px;
      width: 92%;
      height: 5px;
      background: ${({ theme }) => theme.colors.secondary};
    }
    span {
      color: ${({ theme }) => theme.colors.secondary};

      &:active {
        color: ${({ theme }) => theme.colors.secondary};
      }
      &:visited {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
  li:hover {
    a {
      color: ${({ theme }) => theme.colors.secondary};
      transition: all .3s;
    }
    span {
      color: ${({ theme }) => theme.colors.secondary};
      transition: all .3s;
    }
  }
  li::before{
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    width: 0;
    height: 5px;
    background: ${({ theme }) => theme.colors.secondary};
    transition: .3s;
  }
  li:hover::before {
    width: 92%;
  } 
`;

export const BurgerUl = styled.ul<BurgerUlProps>`
  padding: 0;
  list-style: none;
  display: flex;
  transition: transform 0.2s ease-in-out;
  z-index: 1000;

  li {
    padding: 18px 10px;
    font-family: ${({ theme }) => theme.fonts.RalewayBold};
    color: ${({ theme }) => theme.colors.white};

    a {
      text-decoration: none;
      &:active {
        color: ${({ theme }) => theme.colors.grey.normal};
      }
      &:visited {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  transform: ${({ openMenu }) => openMenu ? 'translateX(0)' : 'translateX(100%)'};
  flex-flow: column nowrap;
  background: ${({ theme }) => theme.colors.primary.darker};
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  padding-top: 3.5rem;
`;

export const NavLinkContainer = styled.div`

`;

export const Burger = styled.div<BurgerProps>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  z-index: 10000;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 1050px) {
    display: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ theme, openMenu }) => openMenu ? theme.colors.white : theme.colors.primary.normal};
    border-radius: 10px;
    transform-origin: 1px;
    transition: transform 0.23s linear;

    &:nth-child(1) {
      transform: ${({ openMenu }) => openMenu ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${({ openMenu }) => openMenu ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ openMenu }) => openMenu ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export const DropdownLink = styled.div<DropdownLinkProps>`
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey.dark};


  .dropdown-content {
    display: ${({ dropdownopen }) => (dropdownopen ? 'block' : 'none')};
    position: absolute;
    background-color: ${({ theme }) => theme.colors.grey.light};
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 5px;

    animation: fadeIn 0.55s;
    -webkit-animation: fadeIn 0.55s;
    -moz-animation: fadeIn 0.55s;
    -o-animation: fadeIn 0.55s;
    -ms-animation: fadeIn 0.55s;
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    @-moz-keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    @-webkit-keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    @-o-keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    @-ms-keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }
  }

  .dropdown-content a {
    color: ${({ theme }) => theme.colors.grey.dark};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;
