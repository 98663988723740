import { FunctionComponent } from "react";
import TopBanner from "../../components/TopBanner/TopBanner";
import { useTheme } from "styled-components";
import { BriefingBpContainer, BriefingBpContent } from "./styles";
import SocialResponsabilityBanner from "../../images/banner-responsabilidade-social.png";
import SportsEncouragement from "../../images/incentivo-esporte.png";
import MailMan from "../../images/correios.png";
import CorreiosLogo from "../../images/logo-correios.png";
import CentroIntegracao from "../../images/centro-Integracao.png";
import CentroIntegracaoLogo from "../../images/logo-centro-integracao.png";
import Hospital from "../../images/hospital-pequeno-principe.png";
import HospitalLogo from "../../images/logo-hospital-pequeno-principe.png";
import Sesc from "../../images/sesc.png";
import SescLogo from "../../images/sesc-logo.png";
import Education from "../../images/educacao-e-oportunidades.png";
import Diversity from "../../images/diversidade.png";
import PsychologicalSupport from "../../images/acolhimento-psicologico.png";
import LinkFooter from "../../components/LinkFooter/LinkFooter";

interface SocialResponsibilityProps {

}

const SocialResponsibility: FunctionComponent<SocialResponsibilityProps> = () => {
  const theme = useTheme()
  const socialResponsibilityContent = (
    <>
      Uma das grandes preocupações da BP é a de oferecer um serviço de qualidade e que seja feito de forma responsável, seja perante a sociedade e comunidades que estão em contato conosco, o meio ambiente e principalmente nossos colaboradores. Pensando nisso, atuamos em diversas áreas com a finalidade de promover responsabilidade social e corporativa.
    </>
  );

  return (
    <>
      <TopBanner
        title="Responsabilidade social corporativa"
        content={socialResponsibilityContent}
        shadowColor={theme.colors.secondary}
        bannerImg={SocialResponsabilityBanner}
        bgColor={theme.colors.grey.light}
        bannerHeight={700}
        bannerHeightMobile="calc(1100px - 90vw)"
      />

      <BriefingBpContainer>
        <BriefingBpContent>
          <div className="midia-container">
            <img src={SportsEncouragement} alt="Imagem de várias pessoas em uma competição de corrida" />
          </div>

          <div className="text-container">
            <h2>Incentivo ao esporte</h2>
            <p>
              Acompanhamento na preparação e patrocínio de atletas para a realização de maratonas, triátlon, corrida de rua, natação e ciclismo.
            </p>
          </div>
        </BriefingBpContent>

        <BriefingBpContent className="column-reverse">
          <div className="text-container">
            <img src={CorreiosLogo} alt="Logo da empresa Correios" />

            <p>
              Os Correios, maior empresa de logística do país, reconheceu a BP pelos mais de 20 anos de parceria e lançou um selo comemorativo.
            </p>
          </div>

          <div className="midia-container">
            <img src={MailMan} alt="Imagem de um carteiro dos correios com um pacote da mão" />
          </div>
        </BriefingBpContent>

        <BriefingBpContent>
          <div className="midia-container">
            <img src={CentroIntegracao} alt="Imagem da sede da empresa Centro de Integração Empresa-Escola" />
          </div>

          <div className="text-container">
            <img src={CentroIntegracaoLogo} alt="Logo da empresa Centro de Integração Empresa-Escola" />
            <p>
              Parceiros do CIEE – PR desde 2001, reconhecidos pela formação de mão de obra por intermédio de programas de aprendizagem e estágio.
            </p>
          </div>
        </BriefingBpContent>

        <BriefingBpContent className="column-reverse">
          <div className="text-container">
            <img src={HospitalLogo} alt="Logo do hospital Pequeno Príncipe" />
            <p>
              A BP é hoje a maior mobilizadora do projeto Rede do Bem do Hospital Pequeno Príncipe, referência em tratamento infantil.
            </p>
          </div>

          <div className="midia-container">
            <img src={Hospital} alt="Foto da Sede do hospital Pequeno Princípe" />
          </div>
        </BriefingBpContent>

        <BriefingBpContent>
          <div className="midia-container">
            <img src={Sesc} alt="Imagem de uma bandeja contendo um prato de comida, frutas e talheres" />
          </div>

          <div className="text-container">
            <img src={SescLogo} alt="Logo da empresa Sesc" />
            <p>
              Com o suporte do Sesc promovemos uma opção mais saudável no âmbito da alimentação. Com essa parceria nossos colaboradores têm almoço balanceado, feito por uma série de nutricionistas capacitados.
            </p>
          </div>
        </BriefingBpContent>

        <BriefingBpContent className="column-reverse">
          <div className="text-container">
            <h2>Educação e oportunidades</h2>
            <p>
              Acreditamos que um dos pilares para um futuro melhor está no incentivo à educação e nas oportunidades de empregabilidade para jovens talentos. Por isso, somos parceiros das instituições ESPRO, SENAC e CIEE em Fortaleza, na realização de programas de estágio e jovem aprendiz.
            </p>
          </div>

          <div className="midia-container">
            <img src={Education} alt="Imagem de uma mulher utilizando um notebook" />
          </div>
        </BriefingBpContent>

        <BriefingBpContent >
          <div className="midia-container">
            <img src={Diversity} alt="Imagem de várias pessoas sorrindo" />
          </div>

          <div className="text-container">
            <h2>Diversidade</h2>
            <p>
              Somos uma empresa diversa nos fatores mais variados, desde gênero à sexualidade e etnias e por isso buscamos sempre trazer o debate do respeito e conscientização. Acreditamos que é nosso dever favorecer o respeito entre os indivíduos que compõem a organização.
            </p>
          </div>
        </BriefingBpContent>

        <BriefingBpContent className="column-reverse">
          <div className="text-container">
            <h2>Acolhimento psicológico</h2>
            <p>
              Quando falamos da constituição do indivíduo não é apenas a saúde física que devemos ter como foco. Através do acolhimento psicológico atendemos e entendemos as dores psíquicas dos nossos colaboradores, nos permitindo criar um ambiente mais saudável e seguro para todos.
            </p>
          </div>

          <div className="midia-container">
            <img src={PsychologicalSupport} alt="Imagem de duas pessoas dando as mãos em uma clínica" />
          </div>
        </BriefingBpContent>

      </BriefingBpContainer>
      <LinkFooter
        title="Se identificou com a BP?"
        subtitle="Conheça nossa estrutura para colaboradores e candidate-se a uma vaga"
        linkTo="/carreira/"
        buttonText="Conheça mais"
      />
    </>
  );
}

export default SocialResponsibility;