export function isValidCPF(cpf) {
  cpf = cpf.toString()
  if (typeof cpf !== 'string') {
    return false
  }

  cpf = cpf.replace(/\D/g, '')
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false
  }

  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }

  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) {
    resto = 0
  }

  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  soma = 0
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }

  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) {
    resto = 0
  }

  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}

export function isValidCNPJ(cnpj) {
  var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  var c = String(cnpj).replace(/[^\d]/g, '')

  if (c.length !== 14)
    return false

  if (/0{14}/.test(c))
    return false

  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
    return false

  for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
  if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
    return false

  return true
}