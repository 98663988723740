import React from 'react';
import LinkFooterContainer from './styles';
import { useTheme } from 'styled-components';
import LinkButton from '../Buttons/LinkButton';

type LinkFooterProps = {
  title: any;
  subtitle?: string;
  linkTo: string;
  buttonText: string;
};

const LinkFooter: React.FC<LinkFooterProps> = ({ title, subtitle, linkTo, buttonText }) => {
  const theme = useTheme();

  return (
    <LinkFooterContainer>
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <LinkButton
        bgColor={theme.colors.white}
        textColor={theme.colors.secondary}
        linkTo={linkTo}
        openInNewTab={false}
        title={buttonText}
        hoverColor={theme.colors.grey.light}
        width={143}
      />
    </LinkFooterContainer>
  );
};

export default LinkFooter;