import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import BannerPattern from '../../images/pattern.svg';
import OurServicesIcon from '../../images/icon-nossos-servicos.svg';
import TecnologiesIcon from '../../images/icon-tecnologia.svg';
import QualityIcon from '../../images/icon-qualidade.svg';
import LinkButton from "../../components/Buttons/LinkButton";
import Evento from "../../images/evento.jpg";
import ResponsabilidadeSocial from "../../images/responsabilidade-social.png";
import EncarregadoIcon from '../../images/encarregado.svg';
import TrabalheBp from "../../images/trabalhe-na-bp.png";
import AntiFraud from "../../images/img-antifraude.png";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AntiFraudPattern from '../../images/pattern-antifraud.png';
import LogoBpBW from '../../images/Logo-bp-white.png';
import LogoIgeoc from '../../images/logo-igeoc.png';
import {
  HomeBannerContainer,
  HomeBanner,
  BannerFilter,
  PatternContainer,
  BannerPhrase,
  SolutionsContainer,
  BriefingBpContainer,
  BriefingBpContent,
  InChargeContainer,
  AssociadosContainer,
  AssociadosContent,
  AntiFraudContainer,
  AntiFraudContent,
  GetInContactContainer
} from "./styles";
import { useTheme } from "styled-components";
import { Grid } from "@mui/material";

interface HomePageProps {

}

const HomePage: FunctionComponent<HomePageProps> = () => {
  const theme = useTheme();

  return (
    <>
      <HomeBannerContainer>
        <HomeBanner />
        <BannerFilter />
        <PatternContainer>
          <img src={BannerPattern} />
          <BannerPhrase>
            <h1>
              Assistência <br />
              completa com <br />
              recuperação <br />
              de patrimônio
            </h1>
          </BannerPhrase>
        </PatternContainer>
      </HomeBannerContainer>

      <SolutionsContainer>
        <h2>Conheça Nossas Soluções</h2>

        <div className="links-container">
          <div className="iconed-links">
            <img src={OurServicesIcon} alt="Nossos Serviços" />
            <p>Nossos Serviços</p>
          </div>
          <div className="iconed-links">
            <img src={TecnologiesIcon} alt="Nossas Tecnologias" />
            <p>Tecnologia</p>
          </div>
          <div className="iconed-links">
            <img src={QualityIcon} alt="Qualidade" />
            <p>Qualidade</p>
          </div>
        </div>

        <LinkButton
          bgColor={theme.colors.primary.normal}
          textColor={theme.colors.white}
          linkTo="/nossas-solucoes"
          openInNewTab={false}
          title="Saiba mais"
          hoverColor={theme.colors.primary.normalActive}
        />

      </SolutionsContainer>

      <BriefingBpContainer>

        <BriefingBpContent>
          <div className="midia-container event">
            <img src={Evento} alt="Imagem de várias pessoas comemorando" />
          </div>
          <div className="text-container">
            <h2>A Bellinati Perez</h2>
            <p>A Bellinati Perez atua na recuperação de crédito para as
              principais instituições financeiras do país.</p>
            <LinkButton
              bgColor={theme.colors.primary.normal}
              textColor={theme.colors.white}
              linkTo="/sobre"
              openInNewTab={false}
              title="Conheça mais"
              hoverColor={theme.colors.primary.normalActive}
            />
          </div>
        </BriefingBpContent>

        <BriefingBpContent className="column-reverse">
          <div className="text-container">
            <h2>Responsabilidade social corporativa</h2>
            <p>Na BP, as questões relacionadas à responsabilidade sócio-ambiental e governança estão intimamente relacionadas com as práticas de preservação do meio ambiente e ambiente interno saudável.</p>
            <LinkButton
              bgColor={theme.colors.primary.normal}
              textColor={theme.colors.white}
              linkTo="/responsabilidade-social"
              openInNewTab={false}
              title="Conheça mais"
              hoverColor={theme.colors.primary.normalActive}
            />
          </div>
          <div className="midia-container">
            <img src={ResponsabilidadeSocial} alt="Imagem de várias pessoas em uma competição de corrida" />
          </div>
        </BriefingBpContent>

        <BriefingBpContent>
          <div className="midia-container">
            <img src={TrabalheBp} alt="Foto de uma mulher sorrindo" />
          </div>
          <div className="text-container">
            <h2>Trabalhe na Bellinati Perez</h2>
            <p>
              Um lugar incrível para o seu crescimento, cheio de desafios. Venha fazer parte do time BP.
              <br />
              <br />
              Temos vagas para atuação presencial, home office ou híbrido (presencial e home office).
              <span> Confira as vagas em aberto.</span>
            </p>
            <LinkButton
              bgColor={theme.colors.secondary}
              textColor={theme.colors.white}
              linkTo="/carreira"
              openInNewTab={false}
              title="Conheça mais"
              hoverColor={theme.colors.secondaryHover}
            />
          </div>
        </BriefingBpContent>

        <InChargeContainer>
          <img src={EncarregadoIcon} />
          <p className="info">Encarregado pelo tratamento de dados da Bellinati Perez e Empresas ligadas.</p>
          <p className="incharge">Pio Carlos Freiria Junior</p>
          <p className="email">dpo@bellinatiperez.com.br</p>
          <LinkButton
            bgColor={theme.colors.secondary}
            textColor={theme.colors.white}
            linkTo="mailto:dpo@bellinatiperez.com.br"
            openInNewTab={false}
            title="Entre em contato"
            hoverColor={theme.colors.secondaryHover}
          />
        </InChargeContainer>

      </BriefingBpContainer>
      <AssociadosContainer>
        <AssociadosContent>
          <div className="text-container">
            <div className="contents">
              <h4>Associados IGEOC</h4>
              <p>
                O Instituto GEOC (Gestão de Excelência Operacional em Cobrança) tem a missão de promover o fortalecimento e a excelência da indústria de cobrança. O instituto reúne as principais empresas de recuperação de crédito do país, as quais empregam milhares de funcionários especializados na área. <br /><br />
                Sob uma inovadora perspectiva e cultura de atendimento, suas associadas atuam em importantes segmentos do mercado, e <b>a BP faz parte disso.</b>
              </p>
            </div>
          </div>
          <div className="image-container">
            <img src={LogoIgeoc} alt="Logo da IGEOC" />
          </div>
        </AssociadosContent>
      </AssociadosContainer>

      <AntiFraudContainer>
        <AntiFraudContent>
          <div className="image-container">
            <img src={AntiFraud} alt="Pessoa mexendo em um notebook com cadeados ao redor" className="antifraud-image" />
            <img src={AntiFraudPattern} alt="Imagens de cadeados" className="antifraud-pattern" />
          </div>
          <div className="text-container">
            <div className="content-container">
              <div className="antifraud-title">
                <div className="title-background">
                  <h3>ATENÇÃO</h3>
                </div>
                <h4>Pague seu débito ao credor e não ao fraudador.</h4>
              </div>
              <div className="antifraud-content">
                <h5>A BP e Você Contra a Fraude de Boletos</h5>
                <p>
                  Apresentamos algumas dicas para que você não caia em golpes de fraude com boletos falsos.
                  Ao suspeitar ou identificar de um e-mail, uma mensagem ou uma página falsa em nome da BP, não clique em links nem abra ou baixe arquivos, e nos avise enviando uma mensagem para: <a href="mailto:contatobp@bellinatiperez.com.br">contatobp@bellinatiperez.com.br</a>
                </p>
              </div>
              <LinkButton
                bgColor={theme.colors.white}
                textColor={theme.colors.secondary}
                linkTo="/recomendacoes-antifraude"
                openInNewTab={false}
                title="Ver mais dicas sobre o assunto"
                width={230}
                hoverColor={theme.colors.grey.light}
              />
            </div>
          </div>
        </AntiFraudContent>
      </AntiFraudContainer>

      <GetInContactContainer container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <img src={LogoBpBW} alt="Iniciais da Bellinati Perez" />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div>
            <h3>
              Entre em contato e saiba <br />
              como a Bellinati Perez <br />
              pode te ajudar
            </h3>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <LinkButton
            bgColor={theme.colors.white}
            textColor={theme.colors.secondary}
            linkTo="/fale-com-a-gente"
            openInNewTab={false}
            title="Entre em contato"
            width={230}
            hoverColor={theme.colors.grey.light}
          />
        </Grid>
      </GetInContactContainer>
    </>
  );
}

export default HomePage;