import formatString from 'format-string-by-pattern';

export const format = (mask: any) => {
  return {
    format: (value: any) => {
      if (!value) return '';
      return formatString(mask, value);
    },
    parse: (fieldValue: any) => fieldValue.replace(/[^\d]/g, ''),
  };
};