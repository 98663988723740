import styled from "styled-components";
import { Theme } from "../../config/Theme";
import AttributesBanner from "../../images/imagem-visao-valores.png";
import { Grid } from "@mui/material";

interface ThemeProps {
  theme: Theme
}

export const AssociateLawyersContainer = styled.div<ThemeProps>`
  .role {
    font-family: ${({ theme }) => theme.fonts.RalewayBold};
    color: ${({ theme }) => theme.colors.secondary};
  }

  p:nth-of-type(3) {
    font-family: ${({ theme }) => theme.fonts.RalewayBold};
    color: ${({ theme }) => theme.colors.secondary};
  }

  p {
    font-size: 1.143rem;
    font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    color: ${({ theme }) => theme.colors.grey.dark};
  };

  p:nth-of-type(2) {
    margin-bottom: 10px;
  }
`;

export const BpAttributesContainer = styled.section<ThemeProps>`
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-image: url(${AttributesBanner});
  background-size: cover;
  padding: 200px 50px 200px 50px;
  background-repeat: no-repeat;

  @media (max-width: 764px) {
    background-position: top;
    padding: 100px 50px 100px 50px;
  }
`;

export const AttributesContentContainer = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  padding-right: 250px;

  @media (max-width: 764px) {
    width: 100%;
    div {
      margin-bottom: 20px;
    }
  }

  @media(max-width: 1500px) {
    padding-right: 0;
  }

  div {
    margin-bottom: 48px;
  }

  div:nth-of-type(3) {
    margin-bottom: 0;
  }
`;

export const AttributesContent = styled.div<ThemeProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  h4 {
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 1.429rem;
  }
  p {
    margin-top: 10px;
    font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 1.286rem;
  }
  img {
    margin-right: 20px;
  }


  @media (max-width: 764px) {
    flex-direction: column;
    width: 100%;
    align-items: center;

    img {
      margin-right: 0;
    }
  }
`;

export const BpBuildingsAddressesContainer = styled(Grid) <ThemeProps>`
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.grey.light};
  padding: 50px 0px 0px 170px;

  @media (max-width: 764px) {
    padding: 50px 0 0 0;
  }
`;

export const BuildingsAddresses = styled(Grid) <ThemeProps>`
  padding: 0 20px;

  @media (max-width: 1199px) {
    padding-right: 170px;
  }

  @media (max-width: 640px) {
    padding-right: 0px;
  }

  div {
    padding-right: 10px;
  }

  .title-container {
    margin-bottom: 40px;
  }

  h3 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 2.8rem;
  }
  p {
    font-family: ${({ theme }) => theme.fonts.RalewayRegular};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 1.143rem;
    line-height: 1.5;
  }
  h5 {
    font-family: ${({ theme }) => theme.fonts.RalewayBold};
    color: ${({ theme }) => theme.colors.grey.dark};
    font-size: 1.143rem;
    margin-bottom: 6px;
  }
  .address {
    color: ${({ theme }) => theme.colors.grey.lighter};
  }

  a {
    font-size: 1.143rem;
    color: ${({ theme }) => theme.colors.primary.normal};
    font-family: ${({ theme }) => theme.fonts.RalewaySemiBold};
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    width: 130px;
    margin-top: 16px;
    margin-bottom: 24px;

    @media (max-width: 764px) {
      width: 100%;
    }
  }

  .last-map-link {
    margin-bottom: 60px;
  }

  span {
    margin-left: 5px;
  }

  .chip-mb {
    margin-bottom: 16px;
  }
`;

export const BuildingsImageContainer = styled(Grid) <ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  img {
    text-align: center;
    padding-left: 25px;

    @media (max-width: 764px) {
      width: 100%;
      text-align: left;
    }
  }
`;

export const CityChip = styled.div<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.RalewayBold};
  font-size: 1.143rem;
  border-radius: 48px;
  width: 130px;
`;