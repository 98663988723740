import styled from "styled-components";
import { Theme } from "../../config/Theme";


interface ThemeProps {
  theme: Theme;
  disabled?: boolean
}

export const FormContainer = styled.div`
  display: flex;
  padding: 20px 40px;
  justify-content: space-between;

  @media (max-width: 1329px) {
    flex-direction: column;
  }
`;

export const FormContent = styled.div`
  width: 50%;
  @media (max-width: 1329px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;

  input {
    background-color: white;
  }

  .text-area {
    div:nth-of-type(1) {
      background: white !important;
    }
  }

  label {
    font-family: ${({ theme }) => theme.fonts.PoppinsMedium};
    font-size: 1.143rem;
    margin-bottom: 5px;
    margin-left: 2px;
  }
`;

export const FormInfoContainer = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;

  h3 {
    font-family: ${({ theme }) => theme.fonts.BalooTammaSemiBold};
    font-size: 2.8rem;
    line-height: 1;
    margin-bottom: 15px;
  };

  p {
    font-family: ${({ theme }) => theme.fonts.PoppinsRegular};
    font-size: 1.143rem;
    line-height: 1.5;
    margin-bottom: 20px;
  };

  a {
    margin-bottom: 15px;
  }

  @media (max-width: 1329px) {
    width: 100%;
  }
`;

export const FormButton = styled.button<ThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.grey.lightActive : theme.colors.primary.normal};
  padding: 10px;
  font-family: ${({ theme }) => theme.fonts.PoppinsSemiBold};
  font-size: 1rem;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: none;
  cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
  transition: all 0.15s linear;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background: ${({ theme, disabled }) => disabled ? theme.colors.grey.lightActive : theme.colors.primary.normalActive};
  }
`;