import React from 'react'
import { BpIsoContainer, BpCertContainer, FooterContainer, FooterContent, BpCertText, FooterLogoContainer, FooterLinksContainer, FooterLinkContent, SocialMediasBp, LogoEnergiaRenovavel } from './styles';
import SafeBrowsingLogo from "../../images/google-safe-browsing.png";
import IsoLogo from "../../images/iso-logo.png";
import GptwLogo from "../../images/selo-gptw.png";
import BpLogo from "../../images/Nova-Logo-Bp.svg";
import InstagramLogo from "../../images/instagram.svg";
import FacebookLogo from "../../images/facebook.svg";
import LinkedinLogo from "../../images/linkedin-icon.svg";
import LogoEnergia from "../../images/LogoEnergiaRenovavel.png"

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterLinksContainer>
          <FooterLinkContent>
            <h3>Mapa do site</h3>
            <a href="/sobre/">A Bellinati Perez</a>
            <a href="/responsabilidade-social/">Responsabilidade corporativa</a>
            <a href="/nossas-solucoes/">Nossas soluções</a>
            <a href="/carreira/">Carreira</a>
            <a href="/fale-com-a-gente/">Fale com a gente</a>
            <a href="/ouvidoria/">Ouvidoria</a>
          </FooterLinkContent>

          <FooterLinkContent>
            <h3>Nossas políticas</h3>
            <a href="/files/BP01.16-Politica-de-Fornecedores_2023_v3.1.pdf" target="_blank" >Política de Fornecedores</a>
            <a href="/files/Politica-Corporativa-de-Seguranca-da-Informacao_Versao-6.2-Dezembro-2023-11.12.23.pdf" target="_blank" >Política Corporativa de Segurança da Informação</a>
            <a href="/files/BP-POLITICA-PRIVACIDADE-E-DADOS-Versao-3.2-dez-23-BP-Gestao-11.12.23.pdf" target="_blank" >Política de privacidade BP Gestão</a>
            <a href="/files/BP - Política de Retenção e Descarte de Dados Pessoais - 2024.pdf" target="_blank" >Política de Retenção e Descarte de Dados Pessoais</a>
          </FooterLinkContent>

          <FooterLinkContent>
            <h3>Contato</h3>
            <p>Telefone: (41) 3207-9291</p>
            <p>Endereço: Avenida Duque de Caxias, 882 – Maringá</p>
            <p>CNPJ: 13.600.461/0001-47</p>
            <p>E-mail: <a href="mailto:contatobp@bellinatiperez.com.br">contatobp@bellinatiperez.com.br</a></p>

            <SocialMediasBp>
              <span>
                <a href="https://www.instagram.com/bellinatiperez" target="_blank">
                  <img src={InstagramLogo} alt="link do facebook da BP" />
                </a>
              </span>
              <span>
                <a href="https://www.facebook.com/bellinatiperez" target="_blank">
                  <img src={FacebookLogo} alt="link do facebook da BP" />
                </a>
              </span>
              <span>
                <a href="https://www.linkedin.com/company/bellinati-perez" target="_blank">
                  <img src={LinkedinLogo} alt="link do Linkedin da BP" />
                </a>
              </span>
            </SocialMediasBp>
          </FooterLinkContent>
        </FooterLinksContainer>

        <FooterContent>
          <FooterLogoContainer>
            <img src={BpLogo} alt="Logo da Bellinati Perez" />
            © 2024 Todos os direitos reservados
          </FooterLogoContainer>

          <BpCertContainer>
            <BpIsoContainer>
              <a href="https://certificadas.gptw.com.br/03404018004568" target="_blank">
                <img src={GptwLogo} alt="Logo do certificado great place to work" />
              </a>
            </BpIsoContainer>

            <BpIsoContainer>
              <a href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.bellinatiperez.com.br%2F" target="_blank">
                <img src={SafeBrowsingLogo} alt="Logo do certificado de site seguro da google" />
              </a>
            </BpIsoContainer>

            <BpIsoContainer>
              <a href="https://www.iso.org/standard/62085.html" target="_blank">
                <img src={IsoLogo} alt="Logo do certificado ISO" />

                <BpCertText>
                  <span>ISO 9001:2015</span>
                </BpCertText>
              </a>
            </BpIsoContainer>

            <BpIsoContainer>
              <a href="https://www.iso.org/standard/iso-iec-27001-2022-v2" target="_blank">
                <img src={IsoLogo} alt="Logo do certificado ISO" />

                <BpCertText>
                  <span>ISO/IEC 27001:2022</span>
                </BpCertText>
              </a>
            </BpIsoContainer>

            <BpIsoContainer>
              <a href="https://www.iso.org/standard/71670.html" target="_blank">
                <img src={IsoLogo} alt="Logo do certificado ISO" />

                <BpCertText>
                  <span>ISO/IEC 27701:2019</span>
                </BpCertText>
              </a>
            </BpIsoContainer>
            <LogoEnergiaRenovavel className="grid-container">
              <img src={LogoEnergia} alt="" />
            </LogoEnergiaRenovavel>
          </BpCertContainer>
        </FooterContent>
      </FooterContainer>
    </>
  );
}

export default Footer;