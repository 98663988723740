import { FunctionComponent } from "react";
import TopBanner from "../../components/TopBanner/TopBanner";
import { useTheme } from "styled-components";
import { SolutionsContainer, SolutionsContent, SolutionsItem, TecnologyInovationContainer, TecnologyInovationImageContainer, TecnologyInovationTopics, TecnologyItems, TecnologyItemsContent, TecnologyItemsTitle } from "./styles";
import { Grid } from "@mui/material";
import OurSolutionsBanner from "../../images/nossas-soluções-banner.png";
import ConsignedIcon from "../../images/icone-consignado.png";
import ShieldedCoinIcon from "../../images/icone-consorcio.png";
import PJIcon from "../../images/icone-pj.png";
import CreditCardIcon from "../../images/icone-cartao-credito.png";
import VehiclesIcon from "../../images/icone-veiculos.png";
import ImobIcon from "../../images/icone-imobiliario.png";
import TecnologyInovationBanner from "../../images/banner-tecnologia-inovacao.png";
import SmsIcon from "../../images/icone-sms.png";
import InovationIcon from "../../images/icone-inovacao.png";
import DigitalNegotiationIcon from "../../images/icone-negociacao-digital.png";
import DataScienceIcon from "../../images/icone-data-science.png";
import MultichannelIcon from "../../images/icone-multicanalidade.png";
import HibridNegotiationIcon from "../../images/icone-negociacao-hibrida.png";
import LinkFooter from "../../components/LinkFooter/LinkFooter";

interface OurSolutionsProps {

}

const OurSolutions: FunctionComponent<OurSolutionsProps> = () => {
  const theme = useTheme();
  const ourSolutionsContent = "A BP atua na recuperação de créditos com e sem garantia, trabalhamos com negociação humana, digital e híbrida. Atuamos junto aos principais players do segmento bancário, instituições financeiras, varejistas e crédito educacional. Em nosso portfólio, atuamos em faixas de atraso que vão de 15 dias a mais de 20 anos e na recuperação de créditos de vários segmentos, como:"
  const footerContent = (<>Entre em contato com a gente <br /> e entenda como a BP pode ajudar você</>);

  return (
    <>
      <TopBanner
        title="Nossas soluções"
        content={ourSolutionsContent}
        shadowColor={theme.colors.secondary}
        bannerImg={OurSolutionsBanner}
        bgColor={theme.colors.grey.light}
        bannerHeight={660}
        bannerHeightMobile="620px"
        bannerHeightMid="650px"
      />
      <SolutionsContainer>
        <SolutionsContent container rowSpacing={5}>
          <SolutionsItem item xs={12} sm={6} md={6} lg={4}>
            <img src={ConsignedIcon} alt="Ícone de uma moeda e um relógio" />
            <p>Consignado</p>
          </SolutionsItem>
          <SolutionsItem item xs={12} sm={6} md={6} lg={4}>
            <img src={ShieldedCoinIcon} alt="Ícone de um escudo com uma moeda no centro" />
            <p>Consórcio</p>
          </SolutionsItem>
          <SolutionsItem item xs={12} sm={6} md={6} lg={4}>
            <img src={PJIcon} alt="Ícone de uma maleta" />
            <p>Pessoa Jurídica</p>
          </SolutionsItem>
          <SolutionsItem item xs={12} sm={6} md={6} lg={4}>
            <img src={CreditCardIcon} alt="Ícone de uma mão segurando uma moeda" />
            <p>Cartão de Crédito</p>
          </SolutionsItem>
          <SolutionsItem item xs={12} sm={6} md={6} lg={4}>
            <img src={VehiclesIcon} alt="Ícone de um carro" />
            <p>Motos, Leves <br /> e pesados</p>
          </SolutionsItem>
          <SolutionsItem item xs={12} sm={6} md={6} lg={4}>
            <img src={ImobIcon} alt="Ícone de um prédio" />
            <p>Imobiliário</p>
          </SolutionsItem>
        </SolutionsContent>
      </SolutionsContainer>

      <TecnologyInovationContainer container>
        <TecnologyInovationImageContainer item xs={12} sm={12} md={4} lg={4}>
          <div>
            <img src={TecnologyInovationBanner} alt="Imagem de um notebook com códigos de programação na tela" />
          </div>
        </TecnologyInovationImageContainer>

        <TecnologyInovationTopics item xs={12} sm={12} md={8} lg={8}>
          <div className="title-container">
            <h3>
              Tecnologia e Inovação
            </h3>
          </div>
          <TecnologyItemsContent container>
            <TecnologyItems xs={12} sm={12} md={6} lg={6}>
              <TecnologyItemsTitle>
                <img src={SmsIcon} alt="Ícone de um celular enviando uma mensagem via SMS" />
                <h5>Radar SMS</h5>
              </TecnologyItemsTitle>
              <p>
                Ferramenta para leitura e
                classificação de respostas de SMS,
                mediante árvore de decisão.
              </p>
            </TecnologyItems>

            <TecnologyItems xs={12} sm={12} md={6} lg={6}>
              <TecnologyItemsTitle>
                <img src={InovationIcon} alt="Ícone com a silhueta de 3 pessoas" />
                <h5>Célula de inovação</h5>
              </TecnologyItemsTitle>
              <p>
                Time dedicado a implementação
                de ferramentas disruptivas de
                inovação tecnológica.
              </p>
            </TecnologyItems>

            <TecnologyItems xs={12} sm={12} md={6} lg={6}>
              <TecnologyItemsTitle >
                <img src={HibridNegotiationIcon} alt="Ícone de um robô e uma pessoa conversando" />
                <h5>Negociação <br /> Híbrida</h5>
              </TecnologyItemsTitle>
              <p>
                Direcionamento das ações para
                canais receptivos de atendimento
                digital e humano visando
                proporcionar a melhor experiência
                ao cliente.
              </p>
            </TecnologyItems>

            <TecnologyItems xs={12} sm={12} md={6} lg={6}>
              <TecnologyItemsTitle>
                <img src={DigitalNegotiationIcon} alt="Ícone de um robô" />
                <h5>Negociação <br /> 100% digital</h5>
              </TecnologyItemsTitle>
              <p >
                Direcionamento das ações para
                nossa Landing Page, com catálogo
                de serviços adaptado à experiência
                do cliente.
              </p>
            </TecnologyItems>
            <TecnologyItems xs={12} sm={12} md={6} lg={6}>
              <TecnologyItemsTitle >
                <img src={DataScienceIcon} alt="Ícone de uma lupa" />
                <h5>Data science e <br /> analytics</h5>
              </TecnologyItemsTitle>
              <p>
                Análise da árvore de decisão,
                modelagem preditiva, redes
                neurais, implementação de
                pontuação, validação,
                monitoramento e desenvolvimento
                de scorecard. Assim, possibilitamos
                ações assertivas utilizando canais
                de atendimento, que atendam
                melhor o cliente.
              </p>
            </TecnologyItems>

            <TecnologyItems xs={12} sm={12} md={6} lg={6}>
              <TecnologyItemsTitle>
                <img src={MultichannelIcon} alt="Ícone exibindo 3 setas apontadas para cima" />
                <h5>Multicanalidade</h5>
              </TecnologyItemsTitle>
              <p>
                Permite que o usuário interaja com
                a empresa por meio de vários
                canais, com uma jornada única de
                atendimento. Ele pode começar o
                atendimento via telefone, continuar
                por chat e terminar por e-mail.
              </p>
            </TecnologyItems>
          </TecnologyItemsContent>
        </TecnologyInovationTopics>
      </TecnologyInovationContainer>

      <LinkFooter
        title={footerContent}
        buttonText="Fale conosco"
        linkTo="/fale-com-a-gente/"
      />
    </>
  );
}

export default OurSolutions;