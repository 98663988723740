import { FunctionComponent } from "react";
import TopBanner from "../../components/TopBanner/TopBanner";
import { useTheme } from "styled-components";
import CarrerBanner from "../../images/banner-carreira.png";
import GptwImg from "../../images/selo-GPTW-Brasil-2023.png";
import LinkButton from "../../components/Buttons/LinkButton";
import EnchargedIcon from "../../images/icone-crescimento.png";
import WheelChairIcon from "../../images/icone-pcd.png";
import GptwIcon from "../../images/icone-gptw.png";
import ToothIcon from "../../images/icone-dente.png";
import DoctorIcon from "../../images/icone-medico.png";
import RestaurantsIcon from "../../images/icone-restaurantes.png";
import GymIcon from "../../images/icone-academias.png";
import CollegeIcon from "../../images/icone-faculdades.png";
import TrainingBanner from "../../images/treinamento.png";
import LinkFooter from "../../components/LinkFooter/LinkFooter";
import { BpBenefits, CarreerBannerContent, CarreerBpInfoContainer, CarreerOportunities, ReasonsToWorkContainer, SendCvContainer, TrainingContainer } from "./styles";

interface CarreerProps {

}

const Carreer: FunctionComponent<CarreerProps> = () => {
  const theme = useTheme();

  const carrerContent = (
    <CarreerBannerContent>
      Um lugar incrível para o seu crescimento, cheio de desafios. Venha fazer parte do time BP.
      Temos vagas para atuação presencial, home office ou híbrido (presencial e home office).
      <a href="https://bellinatiperez.gupy.io/" target="blank"> Confira as vagas em aberto.</a>
    </CarreerBannerContent>
  )

  return (
    <>
      <TopBanner
        title="Trabalhe com a gente"
        content={carrerContent}
        shadowColor={theme.colors.secondary}
        bannerImg={CarrerBanner}
        bgColor={theme.colors.grey.light}
        bannerHeight={620}
        bannerHeightMobile="700px"
        bannerHeightMid="650px"
        topContent={GptwImg}
      />
      <CarreerBpInfoContainer>
        <p>
          Nossas instalações estão preparadas para todas as formas de atuação,
          respeitando as recomendações de prevenção sanitária e demais normas de segurança do trabalho.
          Principalmente para o recebimento de novos colaboradores com necessidades especiais, possuindo instalações com acessibilidade,
          livres de qualquer barreira que impeça essas pessoas de exercerem suas atividades com plena liberdade.
        </p>
        <br />
        <p>
          Investimos constantemente na formação e qualificação de nosso time. A formação inicial e a integração preparam o profissional para rápida adaptação,
          o suporte da academia BP, que atua em reciclagens, treinamentos e desenvolvimento de carreira,
          nos modelos e-learning e presencial, com instrutores internos, externos e parceria com instituições de ensino e treinamentos.
        </p>
        <br />
        <p><b>Educação continuada está no nosso DNA.</b></p>
      </CarreerBpInfoContainer>

      <SendCvContainer>
        <h2>
          Entre para o processo seletivo.
        </h2>
        <LinkButton
          bgColor={theme.colors.secondary}
          textColor={theme.colors.white}
          linkTo="https://bellinatiperez.gupy.io/"
          openInNewTab={true}
          title="Quero me candidatar"
          width={230}
          hoverColor={theme.colors.secondaryHover}
        />
      </SendCvContainer>

      <ReasonsToWorkContainer>
        <h2>Por que trabalhar na BP?</h2>

        <div className="icons-container">
          <div className="iconed-info">
            <img src={EnchargedIcon} alt="Ícone mostrando a silhueta de uma pessoa com uma seta apontada para cima" />
            <p>
              Programas de desenvolvimento e
              formação corporativa de líderes,
              que favorecem o aprimoramento
              constante dos nossos
              profissionais em todos os níveis.
            </p>
          </div>
          <div className="iconed-info">
            <img src={WheelChairIcon} alt="Ícone de uma pessoa em uma cadeira de rodas" />
            <p>
              Damos grande importância a
              contratações de pessoas com
              necessidades especiais,
              oferecemos ambientes
              preparados e um time especializado.
            </p>
          </div>
          <div className="iconed-info">
            <img src={GptwIcon} alt="Ícone do selo GPTW (Great Place to Work)" />
            <p>
              Estamos entre as melhores
              empresas para se trabalhar.
            </p>
          </div>
        </div>
      </ReasonsToWorkContainer>

      <BpBenefits>
        <h2>Além de todos os benefícios, possuímos também:</h2>

        <div className="icons-container">
          <div className="iconed-info">
            <img src={ToothIcon} alt="Ícone de um dente" />
            <p>
              Assistência
              <br />
              odontológica
            </p>
          </div>
          <div className="iconed-info">
            <img src={DoctorIcon} alt="Ícone de um instrumento usado por médicos" />
            <p>
              Consultório
              <br />
              médico
            </p>
          </div>
          <div className="iconed-info">
            <img src={RestaurantsIcon} alt="Ícone de restaurantes" />
            <p>
              Parceria com
              <br />
              restaurantes
            </p>
          </div>
          <div className="iconed-info">
            <img src={GymIcon} alt="Ícone de academias" />
            <p>
              Convênios com
              <br />
              academias
            </p>
          </div>
          <div className="iconed-info">
            <img src={CollegeIcon} alt="Ícone de um capelo de formatura" />
            <p>
              Instituições
              <br />
              de ensino
            </p>
          </div>
        </div>
      </BpBenefits>

      <CarreerOportunities>
        <div className="oportunities-title">
          <h2>Temos oportunidades de atuação em nossas operações, como:</h2>
        </div>
        <div className="oportunities-container">
          <div className="oportunities-content">
            <p>Estagiário</p>
            <p>Negociador de <br /> cobrança</p>
            <p>Assistente jurídico</p>
            <p>Advogado</p>
            <p>Coordenador jurídico</p>
            <p>Supervisor de <br /> cobrança</p>
            <p>Gerente de <br /> planejamento</p>
          </div>
          <div className="oportunities-content">
            <p>Coordenador de <br /> Marketing</p>
            <p>Control Desk</p>
            <p>Analista de MIS</p>
            <p>Cientista de dados</p>
            <p>Superintendente e <br /> diretor</p>
            <p>Profissionais de RH</p>
            <p>Psicólogos</p>
          </div>
          <div className="oportunities-content">
            <p>
              Auxiliares e <br />
              assistentes <br />
              administrativos e <br />
              financeiros
            </p>
            <p>Especialista em <br /> telecomunicações</p>
            <p>Monitores e analistas <br /> de qualidade</p>
            <p>Analistas de <br /> infraestrutura</p>
          </div>
          <div className="oportunities-content">
            <p>Gerente executivo</p>
            <p>Superintendente</p>
            <p>Gerente</p>
            <p>Estagiário</p>
            <p>Programador</p>
            <p>Analistas</p>
            <p>Técnico de suporte</p>
            <p>Gerente de cobrança</p>
          </div>
        </div>
      </CarreerOportunities>

      <TrainingContainer>
        <div className="image-container">
          <img src={TrainingBanner} alt="Imagem de uma mulher conduzindo uma reunião em um escritório" />
        </div>
        <div className="text-container">
          <h2>Treinamento</h2>
          <p>
            O ato de desenvolver tem vínculo direto nas raízes organizacionais da BP,
            faz parte da cultura da empresa e deve ser o propósito, de todos que são
            ou querem se tornar líderes. A BP se preocupa com os seus
            colaboradores desde a sua formação inicial, dando o apoio e suporte
            necessário, visando adaptação e crescimento.
          </p>
          <p>
            Esse acompanhamento proporciona um maior engajamento, profissionais
            mais preparados e uma notável melhoria de fluxos e processos.
          </p>
          <p>
            Nossa proposta de lifelong learning engloba uma integração mais
            robusta e uma revisão dessa integração ainda no período de experiência;
            uma formação continuada de líderes que acelera e
            padroniza as promoções; um programa de desenvolvimento de líderes que
            mantém nossa liderança afinada com os temas do momento e com as novas
            estratégias dos clientes; uma Academia BP, com
            cursos presenciais e on line para vários níveis; mentoria
            e coaching para cargos de liderança; além da constante contratação de
            empresas especializadas para cursos focados.
          </p>
        </div>
      </TrainingContainer>


      <LinkFooter
        title="Se identificou com a BP?"
        subtitle="Conheça nossa estrutura para colaboradores e candidate-se a uma vaga"
        buttonText="Conheça mais"
        linkTo="https://bellinatiperez.gupy.io/"
      />
    </>
  );
}

export default Carreer;