import React, { FunctionComponent } from "react";
import { Nav, ContainerGrid } from "./styles";
import NavLinks from "./NavLinks";
import LogoBp from "../../images/Nova-Logo-Bp.svg"
import LinkButton from "../Buttons/LinkButton";
import { Grid } from "@mui/material";
import { useTheme } from "styled-components";
import { Link } from "react-router-dom";

const handleNavClick = ({ currentTarget }: any) => {
  document.querySelectorAll(".active-page").forEach(e => e.classList.remove('active-page'))
  currentTarget.className = "active-page";
}

const NavigationHeader = () => {
  const theme = useTheme();

  return (
    <Nav>
      <ContainerGrid container>
        <Grid item lg={3} md={3} sm={6} xs={6} className="gridItens">
          <div className="logo">
            <Link to="/" onClick={handleNavClick}>
              <img src={LogoBp} alt="Logo BP" />
            </Link>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className="gridItens">
          <NavLinks />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} className="gridItens last">
          <LinkButton
            bgColor={theme.colors.secondary}
            textColor={theme.colors.white}
            linkTo="https://portal.bp.com.vc/login"
            openInNewTab={true}
            title="Negocie suas dívidas"
            hoverColor={theme.colors.secondaryHover}
          />
        </Grid>
      </ContainerGrid>
    </Nav>
  );
}

export default NavigationHeader;