import styled, { useTheme } from "styled-components";
import { Theme } from "../../config/Theme";

interface StyledButtonProps {
  theme: Theme;
  bgcolor: string;
  textcolor: string;
  width: number;
  hovercolor?: string;
  lefticon?: string;
}

export const StyledLinkButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ lefticon }) => lefticon ? 'space-between' : 'center'};
  background: ${({ bgcolor }) => bgcolor};
  padding: 10px;
  font-family: ${({ theme }) => theme.fonts.RalewayBold};
  font-size: 1rem;
  height: 45px;
  width: ${({ width }) => width.toString() + 'px'};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.15s linear;
  text-decoration: none;
  color: ${({ textcolor }) => textcolor};

  &:hover {
    background: ${({ hovercolor }) => hovercolor};
  }

  img {
    margin: 5px;
  }
`;